/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { FC } from "react"
import { HeaderInfo } from "src/components/HeaderInfo"

interface SuccessProps {}

const Success: FC<SuccessProps> = props => {
    return (
        <div sx={{ variant: "text.heading", textAlign: "center", my: [3, 4] }}>
            <HeaderInfo appendTitle="Suscrito Éxitoso"></HeaderInfo>
            <h1>
                ¡Éxito! Te enviaremos actualizaciones una vez que el servicio
                esté disponible.
            </h1>
        </div>
    )
}
export default Success
